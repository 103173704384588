import "core-js";
import "classlist-polyfill/src/index";

import { environment } from "./environments/environment";

if (!environment.production) {
  // Development
  Error["stackTraceLimit"] = Infinity;
  (window as any).__Zone_enable_long_stack_trace = true;
}

if (document["documentMode"] || /Edge/.test(navigator.userAgent)) {
  (window as any).__Zone_enable_cross_context_check = true;
}

import "zone.js";

(window as any)["global"] = window;
global.process = <any>{};
